<template>
  <div>
    <h1 class="outline--text">
      Projects
    </h1>
    <project-search></project-search>
    <v-row>
      <v-col class="outline--text mt-7" style="max-width: 700px">
        <div>
          <span v-if="!isLoading && this.totalCount > 0">
            <p v-if="this.totalCount > this.currentPage * this.itemsPerPage">
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.currentPage * this.itemsPerPage
              }}
              of {{ this.totalCount }} items
            </p>
            <p v-else>
              Showing {{ (this.currentPage - 1) * this.itemsPerPage + 1 }}-{{
                this.totalCount
              }}
              of {{ this.totalCount }} items
            </p>
          </span>
        </div>
      </v-col>
      <v-col>
        <div class="d-flex justify-space-between">
          <v-spacer></v-spacer>
          <v-pagination
            v-if="showPages && pageCount > 1"
            class="my-3"
            v-model="pagination.page"
            :length="pageCount"
            :total-visible="7"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
          ></v-pagination>
          <div class="d-flex mx-10">
            <v-select
              v-model="pagination.rowPerPage"
              item-text="label"
              item-value="attribute"
              :items="countPerPage"
              label="Show items"
              style="max-width: 80px"
            >
            </v-select>
          </div>
          <div class="d-flex">
            <v-select
              item-text="label"
              item-value="attribute"
              :items="sortAttributes"
              label="sortBy"
              v-model="sort.sortBy"
              style="max-width: 180px"
            ></v-select>
            <span v-if="sort.sortBy" class="mt-5 ml-2">
              <v-icon
                v-if="sort.desc"
                title="sortAsc"
                @click="sort.desc = false"
              >
                mdi-sort-descending
              </v-icon>
              <v-icon v-else title="sortDesc" @click="sort.desc = true">
                mdi-sort-ascending
              </v-icon>
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-data-table
      id="itemsTable"
      :headers="headers"
      :loading="isLoading"
      :items="projects"
      :items-per-page="5"
      disable-pagination
      disable-filtering
      disable-sort
      hide-default-footer
      :item-class="itemClass"

    >
      <template v-slot:item.id="{ item }">
        <v-tooltip bottom :max-width="200">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon v-if="item.lockStatus === 'locked'" class="mr-2" style="color: #F44336" small>
              mdi-lock-outline
              </v-icon>
              <v-icon v-else class="mr-2" style="color: #67BB6A" small>
                mdi-lock-open-outline
              </v-icon>
            </span>
          </template>
          <span v-if="item.lockStatus === 'locked'">Project is locked by timeline worker</span>
          <span v-else>Project is unlocked</span>
        </v-tooltip>

        {{ item.id }}
      </template>
      <template v-slot:item.status="{ item }">
        <span v-if="item.status === 'started'" class="green--text">{{ item.status }}</span>
        <span v-else-if="item.status === 'paused'" class="orange--text">{{ item.status }}</span>
        <span v-else-if="item.status === 'new'" class="blue--text">{{ item.status }}</span>
        <span v-else class="grey--text">{{ item.status }}</span>
      </template>
      <template v-slot:item.name="{ item }">
        <router-link
            class="text--secondary"
            :to="{
            name: 'ProjectItemLogs',
            params: { id: item.id },
          }"
        >
          <img v-bind:src="item.img" alt="" class="mr-2" />
          {{ item.name }}
        </router-link>
      </template>
      <template v-slot:item.pausedAt="{ item }">
        {{ item.pausedAt || item.deletedAt || '-'}}
      </template>
      <template v-slot:item.Actions="{ item }">
        <router-link
          v-if="canManage"
          :to="{
            name: 'ProjectItemLogs',
            params: { id: item.id },
          }"
          ><v-icon class="mr-2" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon></router-link
        >
      </template>
      <template v-slot:item.accountName="{ item }">
        <router-link
            :to="{
                name: 'AccountListPage',
                query: { email: item.accountEmail },
              }"
        >
          {{ item.accountName }}
        </router-link>
      </template>
      <template v-slot:item.type="{ item }">
        <span v-if="item.type === 'feed'" style="color: #009ca9">{{ item.type }}</span>
        <span v-if="item.type === 'keyword'" style="color: #bb6bd9">{{ item.type }}</span>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import { mapGetters, mapState } from "vuex";

import BaseListPage from "./base/BaseListPage";
import ProjectSearch from "./project/list/ProjectSearch";

import {
  defaultFilters,
  emptyFilters,
  multipleFilters,
} from "@/store/modules/project/state";
import { SET_FILTERS } from "@/store/modules/project/actions";

import acl from "@/mixin/acl";
import {RESOURCE_PROJECTS_MANAGEMENT} from "@/store/modules/admin/getters";
import {FETCH_PROJECTS} from "@/store/modules/project/actions";

export default {
  name: "ProjectsPage",
  mixins: [acl],
  extends: BaseListPage,
  components: { ProjectSearch },
  props: {
    limit: {
      required: false,
    },
  },
  data() {
    return {
      defaultFilters,
      emptyFilters,
      multipleFilters,
      showPages: false,
      countPerPage: [
        {
          attribute: 20,
          label: "20",
        },
        {
          attribute: 50,
          label: "50",
        },
        {
          attribute: 100,
          label: "100",
        },
        {
          attribute: 5000,
          label: "All",
        },
      ],
      headers: [
        {
          text: "Id",
          value: "id",
          width: "100",
        },
        {
          text: "Project Name",
          value: "name",
          width: "250",
        },
        {
          text: "Account",
          value: "accountName",
          width: "150",
        },
        {
          text: "Email",
          value: "accountEmail",
          width: "150",
        },
        {
          text: "Project Type",
          value: "type",
          width: "50",
          align: "center",
        },
        {
          text: "Project Articles",
          value: "articlesCount",
          width: "100",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          width: "50",
          align: "center",
        },
        {
          text: "Created At",
          value: "createdAt",
          width: "200",
          align: "center",
        },
        {
          text: "Last Checked",
          value: "lastCheckedAt",
          width: "200",
        },
        {
          text: "Paused/Deleted",
          value: "pausedAt",
          width: "200",
          align: "center",
        },
      ],
      sortAttributes: [
        {
          attribute: "articlesCount",
          label: "Articles",
        },
        {
          attribute: "createdAt",
          label: "Created At",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("project", [
      "projectList",
      "totalCount",
      "currentPage",
      "pageCount",
      "offset",
      "formattedFilters",
    ]),
    ...mapState("project", ["sort", "pagination", "filters"]),

    canManage: function () {
      return this.hasAccess([RESOURCE_PROJECTS_MANAGEMENT]);
    },
    itemsPerPage: function () {
      return this.pagination.rowPerPage;
    },
    projects: function () {
      if (this.isLoading) {
        return [];
      }
      return this.projectList;
    },
  },
  watch: {
    "pagination.rowPerPage": function () {
      this.pagination.page = 1;
    },
  },
  methods: {
    applyFilters: function (payload) {
      return this.$store.dispatch(`project/${SET_FILTERS}`, payload.data);
    },
    fetchItems: function () {
      const vm = this;
      vm.isLoading = true;
      vm.$store.dispatch(`project/${FETCH_PROJECTS}`).then(
        () => {
          vm.isLoading = false;
          vm.showPages = true;
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    const vm = this;
    vm.applyRouteParams();
    vm.fetchItems();
  },
};
</script>
